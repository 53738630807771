import React from "react";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import interpolate from "../utils/interpolate";
import LandesgartenschauIcon from "../img/events/landesgartenschau.svg";
import LandesgartenschauHeader from "../components/LandesgartenschauHeader";
import OktoberfestIcon from "../img/events/oktoberfest.svg";

const events = [
  // landesgartenschau;
  new Feature({
    from: "2024-05-15T00:00Z",
    until: "2024-10-06T23:59Z",
    geometry: new Point([1308606, 6134992]), // Heimstetten bahnhof
    style: (map) => () => {
      const zoom = map.getView().getZoom();
      const scale = interpolate(zoom, [
        [10, 0.1],
        [12, 0.2],
        [14, 0.3],
      ]);
      return new Style({
        image: new Icon({
          src: LandesgartenschauIcon,
          scale,
        }),
      });
    },
    stationUic: 8002715,
    stationBanner: <LandesgartenschauHeader />,
    onClick: () => {
      window.open("https://kirchheim2024.de/", "_blank");
    },
  }),
  // Oktoberfest old
  // {
  //   type: "Feature",
  //   properties: {
  //     // name: '',
  //     icon: "oktoberfest",
  //     size: 40,
  //     from: "2023-09-14T00:00Z",
  //     until: "2023-10-03T23:59Z",
  //     onClick: () =>
  //       window.open(
  //         "https://www.s-bahn-muenchen.de/fahren/oktoberfest",
  //         "_blank",
  //       ),
  //     topographicLimit: 11,
  //   },
  //   geometry: {
  //     type: "Point",
  //     coordinates: [11.550552, 48.130945], // Between Hbf and Hackerbrücke
  //   },
  // },
  // Oktoberfest
  new Feature({
    from: "2024-09-21T00:00Z",
    until: "2024-10-06T23:59Z",
    geometry: new Point(fromLonLat([11.550552, 48.130945])), // Between Hbf and Hackerbrücke
    style: (map) => () => {
      const zoom = map.getView().getZoom();
      if (zoom < 11) {
        return null;
      }
      const scale = interpolate(zoom, [
        [10, 0.1],
        [12, 0.2],
        [14, 0.3],
      ]);
      return new Style({
        image: new Icon({
          src: OktoberfestIcon,
          scale,
        }),
      });
    },
    onClick: () => {
      window.open(
        "https://www.s-bahn-muenchen.de/fahren/oktoberfest",
        "_blank",
      );
    },
  }),
];
export default events;
