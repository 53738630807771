import React from "react";
import Style from "ol/style/Style";
import Icon from "ol/style/Icon";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import interpolate from "../utils/interpolate";
import LandesgartenschauIcon from "../img/events/landesgartenschau.svg";
import LandesgartenschauHeader from "../components/LandesgartenschauHeader";
import OktoberfestIcon from "../img/events/oktoberfest.svg";

const events = [
  // landesgartenschau;
  new Feature({
    from: "2024-05-15T00:00Z",
    until: "2024-10-06T23:59Z",
    geometry: new Point([4494554, 2210623]), // Heimstetten bahnhof
    style: (map) => () => {
      const zoom = map.getView().getZoom();
      const scale = interpolate(zoom, [
        [5, 0.2],
        [6.5, 0.3],
      ]);
      const displacementY = interpolate(zoom, [
        [5, 40],
        [8, 60],
      ]);
      return new Style({
        image: new Icon({
          src: LandesgartenschauIcon,
          scale,
          displacement: [0, displacementY],
          anchor: [0.5, 1],
        }),
      });
    },
    stationUic: 8002715,
    stationBanner: <LandesgartenschauHeader />,
    onClick: () => {
      window.open("https://kirchheim2024.de/", "_blank");
    },
  }),
  // Oktoberfest old
  // {
  //   type: "Feature",
  //   properties: {
  //     // name: '',
  //     icon: "oktoberfest",
  //     size: 30,
  //     scale: (res, map) =>
  //       map.getView().getZoom() < 8.5 ? null : Math.max(20 / res, 0.22),
  //     from: "2023-09-14T00:00Z",
  //     until: "2023-10-03T23:59Z",
  //     hideWithExtraLayerTags: true,
  //     displacement: (res, map) =>
  //       map.getView().getZoom() < 9.5 ? [50, -100] : [0, 0],
  //     onClick: () =>
  //       window.open(
  //         "https://www.s-bahn-muenchen.de/fahren/oktoberfest",
  //         "_blank",
  //       ),
  //   },
  //   geometry: {
  //     type: "Point",
  //     coordinates: toLonLat([1282385.7020149657, 6117672.35280045]), // Between Hbf and Hackerbrücke
  //   },
  // },
  // Oktoberfest
  new Feature({
    from: "2024-09-21T00:00Z",
    until: "2024-10-06T23:59Z",
    geometry: new Point([2295577, 1690162]), // [1282385.7020149657, 6117672.35280045]), // Between Hbf and Hackerbrücke
    style: (map) => () => {
      const zoom = map.getView().getZoom();
      const scale = interpolate(zoom, [
        [5, 0.2],
        [6.5, 0.3],
      ]);

      const displacementY = interpolate(zoom, [
        [5, -40],
        [8, -160],
      ]);
      return new Style({
        image: new Icon({
          src: OktoberfestIcon,
          scale,
          displacement: [0, displacementY],
        }),
      });
    },
    onClick: () => {
      window.open(
        "https://www.s-bahn-muenchen.de/fahren/oktoberfest",
        "_blank",
      );
    },
  }),
];
export default events;
